import {AppBar, Container, Toolbar, Typography, styled} from '@mui/material';
import Grid from '@mui/material/Grid2';
import MiniJournal from './Assets/minijournal-hero-image.png';
import Punchline from './Assets/punchline-hero-image.png';



// import Logo from './path/to/logo.png'; // Adjust the path to your logo

const MontserratTypography = styled(Typography)({
  fontFamily: 'Montserrat, sans-serif',
});

export default function Landing() {

    interface MyApp{
        name: string;
        description: string;
        image: string;
        link: string;
    }

    const myAppsArr : MyApp []= [
        {
            name: 'Mini Journal',
            description: 'Fun baby journal for parents',
            image: MiniJournal,
            link: 'https://minijournal.miniapps.io'
        },
        {
            name: 'Punchline.ai',
            description: 'Your AI creative team',
            image: Punchline,
            link: 'https://punchline.ai'
        }
    ]

    function MyAppBar(){
        return(
            <AppBar position="static" sx={{ boxShadow: 'none' }} style={{ backgroundColor: '#2d3436' }}>
            <Toolbar>
              {/* <img src={Logo} alt="Mini Apps Logo" style={{ marginRight: '16px' }} /> */}
              <MontserratTypography variant="h6" style={{ color: '#e84393' }}>
                Mini Apps
              </MontserratTypography>
            </Toolbar>
          </AppBar>
        )
    }

    function MyApp({name, description, image, link} : {name: string, description: string, image: string, link: string}){
        return(
         
            <Grid container size={{ xs: 12, md: 4}} justifyContent="center" alignItems="center" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => window.open(link, '_blank')}>
                <Grid size={{ xs: 12}} style={{ borderRadius: '16px', overflow: 'hidden', backgroundColor: '#fff' }}>
                    <img src={image} alt={name} style={{ width: '100%', height: 'auto' }} />
                </Grid>
                <Grid size={{ xs: 12}}>
                    <Typography variant="h6" style={{ color: '#fdcb6e', fontSize: "1.7rem", fontWeight: '700' }}>{name}</Typography>
                </Grid>
                <Grid size={{ xs: 12}}>
                    <Typography variant="body1" style={{ color: 'white', fontSize: "1.25rem" }} >{description}</Typography>
                </Grid>
            </Grid>        
        )
    }
  return (
    <Container>
    {/* <MyAppBar/> */}
    <Grid container justifyContent="center" alignItems="center" style={{ textAlign: 'center', marginBottom: '100px' }}>
        <Grid size={{ xs: 12}}>
            <Typography variant="h1" style={{fontSize: '150px', fontWeight: '800', color: 'white', lineHeight: '0.8', marginBottom: '70px', marginTop: '40px' }}>mini apps</Typography>
        </Grid>
        <Grid size={{ xs: 12}}>
            <Typography variant="h5" style={{ color: 'white' }}>AI Apps Developed by <a href="https://x.com/azizakgul" style={{ color: '#e84393', textDecoration: 'none' }}>@azizakgul</a></Typography>
        </Grid>
    </Grid>

    <Grid container spacing={4} justifyContent="center" alignItems="center" style={{ textAlign: 'center', marginBottom: '200px' }}>
        {myAppsArr.map((app: MyApp)=>{
            return(
                <MyApp name={app.name} description={app.description} image={app.image} link={app.link} />
            )
        })}
    </Grid>
    
    </Container>
  );
}